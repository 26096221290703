import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { SUPPORT_STEP_ASSIGNEE_TYPES } from 'src/app/constants';
import { SearchablePopoverService } from 'src/services/searchable-popover.service';

import { ChannelSelectorModalComponent } from '../../channel-selector/channel-selector-modal/channel-selector-modal.component';

@Component({
  selector: 'app-action-selector',
  templateUrl: './action-selector.component.html',
  styleUrls: ['./action-selector.component.scss'],
})
export class ActionSelectorComponent {
  @Input() type: string;
  @Input() action: any;
  @Input() disabled: boolean;
  @Input() assigneeLookups: any[];
  @Input() collaboratorTypes: any[];
  @Input() channelLookups: any;

  constructor(
    private searchablePopoverService: SearchablePopoverService,
    private modalCtrl: ModalController,
  ) {}

  showAssigneeOptions(supportStep: any) {
    this.searchablePopoverService.assignees({
      event,
      selectedId: supportStep.assigneeId,
      showCreatedByUserOption: true,
      showPrimaryRepOption: true,
      showNoneOption: true,
      callback: async (id: string, name: string, object: any) => {
        supportStep.assigneeId = id;
        supportStep.assigneeType = object?.type ?? SUPPORT_STEP_ASSIGNEE_TYPES.USER;
        this.assigneeLookups[id] = {
          id,
          displayName: name,
        };
      },
    });
  }

  async showChannelOptions(supportStep: any) {
    const modal = await this.modalCtrl.create({
      component: ChannelSelectorModalComponent,
      componentProps: {
        selectedChannel: supportStep.channelId,
        defaultInternalOnly: true,
        defaultExternalOnly: false,
        hideChannelTypeFilter: true,
        hideSecondaryTeams: false,
      },
    });

    modal.present();

    modal.onDidDismiss().then(({ data }: any) => {
      if (data?.channel) {
        supportStep.channelId = data.channel.id;
        this.channelLookups[data.channel.id] = data.channel.name;
      }
    });
  }
}
