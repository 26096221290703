<ng-container *ngIf="supportStep.assigneeType === 'team' && supportStep.assigneeId">
  <ion-item [disabled]="disabled">
    <ion-label> Assign to individual or cycle through? </ion-label>
    <ion-select
      slot="end"
      interface="popover"
      [(ngModel)]="supportStep.shouldCycleThroughTeamMembers"
      [compareWith]="shouldCycleThroughTeamMembersCompareFn"
    >
      <ion-select-option [value]="false"> Individual </ion-select-option>
      <ion-select-option [value]="true"> Cycle </ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item [disabled]="disabled" *ngIf="supportStep.shouldCycleThroughTeamMembers">
    <ion-label> Cycle to next agent after </ion-label>
    <ion-select
      slot="end"
      interface="popover"
      [(ngModel)]="supportStep.cycleMinutes"
      [compareWith]="cycleMinutesCompareFn"
    >
      <ion-select-option [value]="1"> 1 minute </ion-select-option>
      <ion-select-option [value]="2"> 2 minutes </ion-select-option>
      <ion-select-option [value]="3"> 3 minutes </ion-select-option>
      <ion-select-option [value]="4"> 4 minutes </ion-select-option>
      <ion-select-option [value]="5"> 5 minutes </ion-select-option>
      <ion-select-option [value]="10"> 10 minutes </ion-select-option>
      <ion-select-option [value]="15"> 15 minutes </ion-select-option>
      <ion-select-option [value]="30"> 30 minutes </ion-select-option>
      <ion-select-option [value]="45"> 45 minutes </ion-select-option>
      <ion-select-option [value]="60"> 1 hour </ion-select-option>
      <ion-select-option [value]="120"> 2 hours </ion-select-option>
      <ion-select-option [value]="180"> 3 hours </ion-select-option>
      <ion-select-option [value]="240"> 4 hours </ion-select-option>
      <ion-select-option [value]="300"> 5 hours </ion-select-option>
      <ion-select-option [value]="360"> 6 hours </ion-select-option>
      <ion-select-option [value]="720"> 12 hours </ion-select-option>
      <ion-select-option [value]="1440"> 24 hours </ion-select-option>
      <ion-select-option [value]="2880"> 48 hours </ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item [disabled]="disabled" *ngIf="supportStep.shouldCycleThroughTeamMembers">
    <ion-label> Max number of reassignments </ion-label>
    <ion-select slot="end" interface="popover" [(ngModel)]="supportStep.maxCycles" [compareWith]="maxCyclesCompareFn">
      <ion-select-option [value]="0"> No max </ion-select-option>
      <ion-select-option [value]="1"> 1 </ion-select-option>
      <ion-select-option [value]="2"> 2 </ion-select-option>
      <ion-select-option [value]="3"> 3 </ion-select-option>
      <ion-select-option [value]="4"> 4 </ion-select-option>
      <ion-select-option [value]="5"> 5 </ion-select-option>
    </ion-select>
  </ion-item>
</ng-container>
