import { Component, Input } from '@angular/core';

import { SUPPORT_STEP_KEYWORDS } from 'src/app/constants';
import { AuthService } from 'src/services/auth.service';
import { SearchablePopoverService } from 'src/services/searchable-popover.service';

import { addConditionalRule, supportsConditionalRules } from '../common';

@Component({
  selector: 'app-condition-selector',
  templateUrl: './condition-selector.component.html',
  styleUrls: ['./condition-selector.component.scss'],
})
export class ConditionSelectorComponent {
  @Input() supportStep: any;
  @Input() disabled: boolean;
  @Input() lookups: any;
  @Input() isLoading: boolean;
  @Input() assigneeLookups: any;
  @Input() collaboratorTypes: any[];
  @Input() channelLookups: any;
  @Input() projectId = null;

  SUPPORT_STEP_CONDITION_TYPES = {
    CONTAINS: 'contains',
    NOT_CONTAINS: 'notContains',
    EQUALS: '==',
    NOT_EQUALS: '!=',
  };

  SUPPORT_STEP_KEYWORDS = SUPPORT_STEP_KEYWORDS;

  CONDITION_TYPES = {
    [this.SUPPORT_STEP_CONDITION_TYPES.CONTAINS]: 'contains',
    [this.SUPPORT_STEP_CONDITION_TYPES.NOT_CONTAINS]: 'does not contain',
    [this.SUPPORT_STEP_CONDITION_TYPES.EQUALS]: 'equals',
    [this.SUPPORT_STEP_CONDITION_TYPES.NOT_EQUALS]: 'does not equal',
  };

  CONDITIONS = {
    'tags.id': {
      label: 'Conversation tag list',
      types: [this.SUPPORT_STEP_CONDITION_TYPES.CONTAINS, this.SUPPORT_STEP_CONDITION_TYPES.NOT_CONTAINS],
      searchType: 'tags',
    },
    'customer.tags.id': {
      label: this.authService.tenant.accountsEnabled ? 'Channel tag list' : 'Customer tag list',
      types: [this.SUPPORT_STEP_CONDITION_TYPES.CONTAINS, this.SUPPORT_STEP_CONDITION_TYPES.NOT_CONTAINS],
      searchType: 'tags',
    },
    ticketTypeId: {
      label: 'Ticket type',
      types: [this.SUPPORT_STEP_CONDITION_TYPES.EQUALS, this.SUPPORT_STEP_CONDITION_TYPES.NOT_EQUALS],
      searchType: 'ticketTypes',
    },
    priority: {
      label: 'Priority',
      types: [this.SUPPORT_STEP_CONDITION_TYPES.EQUALS, this.SUPPORT_STEP_CONDITION_TYPES.NOT_EQUALS],
      searchType: 'priorities',
    },
  };

  constructor(
    private searchablePopoverService: SearchablePopoverService,
    private authService: AuthService,
  ) {
    if (this.authService.tenant.accountsEnabled) {
      this.CONDITIONS['account.tags.id'] = {
        label: 'Account tag list',
        types: [this.SUPPORT_STEP_CONDITION_TYPES.CONTAINS, this.SUPPORT_STEP_CONDITION_TYPES.NOT_CONTAINS],
        searchType: 'tags',
      };
    }
  }

  addConditionalRule() {
    return addConditionalRule(this.supportStep);
  }

  supportsConditionalRules() {
    return supportsConditionalRules(this.supportStep);
  }

  removeConditionalRule(branch: number) {
    this.supportStep.branches.splice(branch, 1);
  }

  conditionValues() {
    return Array.from(Object.entries(this.CONDITIONS));
  }

  showSearchOptions(condition: any) {
    this.searchablePopoverService[this.CONDITIONS[condition.field].searchType]({
      event,
      projectId: this.projectId,
      callback: async (id: string, label: string) => {
        if (!this.lookups[condition.field]) {
          this.lookups[condition.field] = {};
        }

        this.lookups[condition.field][id] = label;

        condition.value = id;
      },
    });
  }

  onConditionChange(condition: any, j: number) {
    this.supportStep.branches[j].conditions[0].operator = this.CONDITIONS[condition.field].types[0];
  }
}
